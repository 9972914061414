<template>
  <div id="quick-profile-update">
    <v-stepper v-model="step">
      <v-stepper-header>
        <v-stepper-step step="1" :complete="step > 1">
          Communication Details
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :complete="step > 2" step="2">
          Address Details
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step step="3"> Personal Details </v-stepper-step>

        <v-divider></v-divider>

        <!-- <v-stepper-step step="4">Change Password</v-stepper-step> -->
      </v-stepper-header>
      <br />
      <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
        <b-spinner variant="primary" type="grow" label="Loading..."></b-spinner>
      </div>

      <v-stepper-items>
        <v-stepper-content step="1">
          <v-card>
            <v-form ref="form3" v-model="valid3" lazy-validation>
              <v-card-subtitle>
                <h1 class="text-center text-primary">
                  Welcome, {{ MemeberFullname }}!
                </h1>
                <h3 class="text-center text-info">
                  Kindly take 2 minutes to enter your information. This is an
                  one time process only.
                </h3>
              </v-card-subtitle>
              <v-card-title>
                <span class="headline">Communication Details</span>
              </v-card-title>
              <v-card-text>
                <small>*indicates required field</small>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6" md="3">
                      <label>*Mobile No</label>
                      <v-text-field
                        type="number"
                        v-model="MobileNo"
                        :rules="MobileNoRules"
                        :disabled="MobileNoFlag"
                        :counter="10"
                        v-mask="'##########'"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col
                      align-self="center"
                      cols="12"
                      sm="6"
                      md="3"
                      v-if="!SendOtpFlag"
                    >
                      <v-btn
                        @click.prevent="sendOTP"
                        :loading="SendOtpLoadingFlag"
                        color="primary"
                      >
                        Send OTP to your Mobile Number
                      </v-btn>
                    </v-col>
                    <v-col
                      align="center"
                      cols="12"
                      sm="6"
                      md="3"
                      v-if="VerifyOtpFlag"
                    >
                      <label>Enter your received OTP</label><br />
                      <v-text-field
                        type="number"
                        v-model="OtpNumber"
                        :disabled="OtpNumberFlag"
                        :rules="OtpNumberRules"
                        :counter="4"
                        v-mask="'####'"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col
                      align-self="center"
                      cols="12"
                      sm="6"
                      md="3"
                      v-if="VerifyOtpFlag"
                    >
                      <v-btn
                        @click.prevent="verifyOTP"
                        :loading="VerifyOtpLoadingFlag"
                        color="primary"
                        class="mr-3"
                      >
                        Verify OTP
                      </v-btn>
                      <v-btn
                        @click.prevent="sendOTP"
                        color="primary"
                        class="mr-3"
                        v-if="ResendOtpFlag"
                      >
                        Resend OTP
                      </v-btn>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <label>Is your Whatsapp Number the same?</label>
                      <v-select
                        :reduce="(option) => option.value"
                        :items="CopyMobileNoOptions"
                        :rules="CopyMobileNoRules"
                        v-model="CopyMobileNo"
                        required
                        outlined
                        dense
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" v-if="CopyMobileNo == 2">
                      <label>*Whatsapp No</label>
                      <v-text-field
                        type="number"
                        v-model="WhatsappNo"
                        :rules="WhatsappNoRules"
                        :counter="10"
                        v-mask="'##########'"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <label>*Email Id</label>
                      <v-text-field
                        type="email"
                        v-model="EmailId"
                        :rules="EmailIdRules"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  :disabled="!valid3"
                  @click.prevent="validateStep(1)"
                  color="primary"
                >
                  Continue
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-stepper-content>

        <v-stepper-content step="2">
          <v-card>
            <v-form ref="form2" v-model="valid2" lazy-validation>
              <v-card-title>
                <span class="headline">Address Details</span>
              </v-card-title>
              <v-card-text>
                <small>*indicates required field</small>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6" md="4">
                      <label>*State</label>
                      <v-autocomplete
                        :reduce="(option) => option.value"
                        :loading="StateIdOptionsLoading"
                        :items="StateIdOptions"
                        :rules="StateIdRules"
                        v-model="StateId"
                        append-outer-icon="mdi-refresh"
                        @click:append-outer="getStateIdOptions"
                        required
                        outlined
                        dense
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <label>*District</label>
                      <v-autocomplete
                        :reduce="(option) => option.value"
                        :loading="DistrictIdOptionsLoading"
                        :items="DistrictIdOptions"
                        :rules="DistrictIdRules"
                        v-model="DistrictId"
                        append-outer-icon="mdi-refresh"
                        @click:append-outer="getDistrictIdOptions"
                        required
                        outlined
                        dense
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <label>*City</label>
                      <v-autocomplete
                        :reduce="(option) => option.value"
                        :loading="CityIdOptionsLoading"
                        :items="CityIdOptions"
                        :rules="CityIdRules"
                        v-model="CityId"
                        append-outer-icon="mdi-refresh"
                        @click:append-outer="getCityIdOptions"
                        required
                        outlined
                        dense
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <label>* Street Line 1</label>
                      <v-text-field
                        v-model="Line1"
                        :rules="Line1Rules"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <label>Street Line 2</label>
                      <v-text-field
                        v-model="Line2"
                        :rules="Line2Rules"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <label>Area</label>
                      <v-text-field
                        v-model="Area"
                        :rules="AreaRules"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <label>*Pincode</label>
                      <v-text-field
                        type="number"
                        v-model="Pincode"
                        :rules="PincodeRules"
                        :counter="6"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="grey mr-3" @click="step = 1"> Previous </v-btn>
                <v-btn
                  :disabled="!valid2"
                  @click.prevent="validateStep(2)"
                  color="primary"
                >
                  Continue
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-stepper-content>

        <v-stepper-content step="3">
          <v-card>
            <v-form ref="form1" v-model="valid1" lazy-validation>
              <v-card-title>
                <span class="headline">Personal Details</span>
              </v-card-title>
              <v-card-text>
                <!-- <small>*indicates required field</small> -->
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6" md="4">
                      <label>Date of Birth</label>
                      <v-menu
                        v-model="menu2"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        lazy
                        transition="scale-transition"
                        offset-y
                        full-width
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            required
                            v-model="DOB"
                            label="Date of Birth"
                            hint="Enter your date of birth"
                            readonly
                            v-on="on"
                            background-color="#F3F6F9"
                            class="
                              form-control form-control-lg form-control-solid
                            "
                            validate-on-blur
                            solo
                            dense
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="DOB"
                          @input="menu2 = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <label>Gender</label>
                      <v-select
                        :reduce="(option) => option.value"
                        :items="GenderOptions"
                        :rules="GenderRules"
                        v-model="Gender"
                        required
                        outlined
                        dense
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <label>Martial Status</label>
                      <v-select
                        :reduce="(option) => option.value"
                        :items="MartialStatusOptions"
                        :rules="MartialStatusRules"
                        v-model="MartialStatus"
                        required
                        outlined
                        dense
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <label>Blood Group</label>
                      <v-select
                        :reduce="(option) => option.value"
                        :loading="BloodGroupIdOptionsLoading"
                        :items="BloodGroupIdOptions"
                        :rules="BloodGroupIdRules"
                        v-model="BloodGroupId"
                        append-outer-icon="mdi-refresh"
                        @click:append-outer="getBloodGroupIdOptions"
                        required
                        outlined
                        dense
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <label>Are you interested to donate blood?</label>
                      <v-select
                        :reduce="(option) => option.value"
                        :items="DonateBloodOptions"
                        :rules="DonateBloodRules"
                        v-model="DonateBlood"
                        required
                        outlined
                        dense
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="grey mr-3" @click="step = 2"> Previous </v-btn>
                <!-- <v-btn
                  :disabled="!valid3"
                  @click.prevent="validateStep(3)"
                  color="primary"
                >
                  Continue
                </v-btn> -->
                <v-btn
                  :disabled="!valid1"
                  @click.prevent="confirmAlert"
                  :loading="SubmitFlag"
                  color="primary"
                >
                  Submit
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-stepper-content>

        <v-form ref="form4" v-model="valid4" lazy-validation> </v-form>
        <!-- <v-stepper-content step="4">
          <v-card>
              <v-card-title>
                <span class="headline">Change Password</span>
              </v-card-title>
              <v-card-text>
                <small>*indicates required field</small>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6" md="4">
                      <label>*Current Password</label>
                      <v-text-field
                        type="password"
                        v-model="CurrentPassword"
                        :rules="CurrentPasswordRules"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <label>*New Password</label>
                      <v-text-field
                        type="password"
                        v-model="NewPassword"
                        :rules="NewPasswordRules"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <label>*Confirm Password</label>
                      <v-text-field
                        type="password"
                        v-model="ConfirmPassword"
                        :rules="ConfirmPasswordRules"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="grey mr-3" @click="step = 3"> Previous </v-btn>
                <v-btn
                  :disabled="!valid4"
                  @click.prevent="confirmAlert"
                  :loading="SubmitFlag"
                  color="primary"
                >
                  Submit
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-stepper-content> -->
      </v-stepper-items>
    </v-stepper>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME,
} from "@/core/services/store/htmlclass.module.js";
import Swal from "sweetalert2";

export default {
  mixins: [common],
  name: "quick-profile-update",
  data() {
    return {
      step: 1,
      valid1: true,
      valid2: true,
      valid3: true,
      valid4: true,
      valid5: true,
      LoadingFlag: false,
      SearchFlag: false,
      SubmitFlag: false,
      SendOtpFlag: false,
      SendOtpLoadingFlag: false,
      VerifyOtpFlag: false,
      VerifyOtpLoadingFlag: false,
      ResendOtpFlag: false,

      MemberId: "",

      MemberName: "",

      // For Step 1

      DOB: "",
      DOBRules: [(v) => !!v || "DOB is required"],
      menu2: false,

      Gender: "",
      GenderRules: [(v) => !!v || "Gender is required"],
      GenderOptions: [
        { value: "", text: "--Select--" },
        { value: 1, text: "Male" },
        { value: 2, text: "Female" },
        { value: 3, text: "Others" },
      ],

      MartialStatus: "",
      MartialStatusRules: [(v) => !!v || "Martial Status is required"],
      MartialStatusOptions: [
        { value: "", text: "--Select--" },
        { value: 2, text: "Unmarried" },
        { value: 1, text: "Married" },
        { value: 3, text: "Single" },
        { value: 4, text: "Dont wish to disclose" },
      ],

      BloodGroupIdRules: [(v) => !!v || "Blood Group is required"],
      BloodGroupId: "",
      BloodGroupIdOptions: [],
      BloodGroupIdOptionsLoading: false,

      DonateBlood: "",
      DonateBloodRules: [(v) => !!v || "Donate Blood is required"],
      DonateBloodOptions: [
        { value: "", text: "--Select--" },
        { value: 1, text: "Yes" },
        { value: 2, text: "No" },
      ],

      // For Step 2

      StateIdRules: [(v) => !!v || "State is required"],
      StateId: "",
      StateIdOptions: [],
      StateIdOptionsLoading: false,

      DistrictIdRules: [(v) => !!v || "District is required"],
      DistrictId: "",
      DistrictIdOptions: [],
      DistrictIdOptionsLoading: false,

      CityIdRules: [(v) => !!v || "City is required"],
      CityId: "",
      CityIdOptions: [],
      CityIdOptionsLoading: false,

      Line1: "",
      Line1Rules: [(v) => !!v || "Line 1 is required"],

      Line2: "",
      Line2Rules: [],

      Area: "",
      AreaRules: [],

      Pincode: "",
      PincodeRules: [(v) => !!v || "Pincode is required"],

      // For Step 3

      MobileNo: "",
      MobileNoFlag: false,
      MobileNoRules: [(v) => !!v || "Mobile No is required"],

      MobileNoVerified: false,

      OtpNumber: "",
      OtpNumberFlag: false,
      OtpNumberRules: [(v) => !!v || "OTP Number is required"],

      CopyMobileNo: "",
      CopyMobileNoRules: [(v) => !!v || "Is Whatsapp No same is required"],
      CopyMobileNoOptions: [
        { value: "", text: "--Select--" },
        { value: 1, text: "Yes" },
        { value: 2, text: "No" },
      ],

      WhatsappNo: "",
      WhatsappNoRules: [(v) => !!v || "Whatsapp No is required"],

      EmailId: "",
      EmailIdRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],

      CurrentPassword: "",
      CurrentPasswordRules: [(v) => !!v || "Current Password is required"],

      NewPassword: "",
      NewPasswordRules: [(v) => !!v || "New Password is required"],

      ConfirmPassword: "",
      ConfirmPasswordRules: [(v) => !!v || "New Password is required"],

      uploadPercentage: 0,
      rows: {},
    };
  },
  mounted() {},
  watch: {
    rows: function () {
      console.log("watch rows");
      var rows = this.rows;
      var n1 =
        typeof rows === "object" && rows !== null
          ? Object.keys(rows).length
          : 0;
      console.log("n1=" + n1);
      console.log({ rows });
      if (n1 > 0) {
        this.MobileNo = rows.MobileNoTxt;
        var VerifiedFlag = rows.VerifiedFlag;
        console.log({ VerifiedFlag });
        this.MobileNoVerified = VerifiedFlag;
        this.MobileNoFlag = VerifiedFlag ? true : false;
        this.SendOtpFlag = VerifiedFlag ? true : false;

        this.EmailId = rows.EmailTxt;

        this.Line1 = rows.Street1;
        this.Line2 = rows.Street2;
        this.Area = rows.Area;
        this.Pincode = rows.Pincode;

        this.DOB = rows.DOB;
        this.Gender = rows.Gender;
        this.MartialStatus = rows.MartialStatus;
        this.BloodGroupId = rows.BloodGroupId;
        this.DonateBlood = rows.DonateBlood;

        this.getStateIdOptions();
      }
    },
    CopyMobileNo: function () {
      console.log("watch CopyMobileNo");
      var CopyMobileNo = this.CopyMobileNo;
      this.WhatsappNo = CopyMobileNo == 1 ? this.MobileNo : this.WhatsappNo;
    },
    BloodGroupIdOptions: function () {
      console.log("watch BloodGroupIdOptions");
      this.LoadingFlag = false;
      this.BloodGroupIdOptionsLoading = false;
    },
    StateIdOptions: function () {
      console.log("watch StateIdOptions");
      this.LoadingFlag = false;
      this.StateIdOptionsLoading = false;
      var rows = this.rows;
      var n1 =
        typeof rows === "object" && rows !== null
          ? Object.keys(rows).length
          : 0;
      console.log({ n1 });
      if (n1 > 0) {
        var StateCode = rows.StateId;
        console.log({ StateCode });
        this.StateId = StateCode;
      }
    },
    StateId: function () {
      console.log("watch StateId");
      this.getDistrictIdOptions();
    },
    DistrictIdOptions: function () {
      console.log("watch DistrictIdOptions");
      this.LoadingFlag = false;
      this.DistrictIdOptionsLoading = false;
      var rows = this.rows;
      var n1 =
        typeof rows === "object" && rows !== null
          ? Object.keys(rows).length
          : 0;
      console.log({ n1 });
      if (n1 > 0) {
        var DistrictCode = rows.DistrictId;
        console.log({ DistrictCode });
        var idx = this.DistrictIdOptions.map((e) => e.value).indexOf(
          DistrictCode
        );
        console.log("idx=" + idx);
        this.DistrictId = idx >= 0 ? DistrictCode : "";
      }
    },
    DistrictId: function () {
      console.log("watch DistrictId");
      this.getCityIdOptions();
    },
    CityIdOptions: function () {
      console.log("watch CityIdOptions");
      this.LoadingFlag = false;
      this.CityIdOptionsLoading = false;
      var rows = this.rows;
      var n1 =
        typeof rows === "object" && rows !== null
          ? Object.keys(rows).length
          : 0;
      console.log({ n1 });
      if (n1 > 0) {
        var CityCode = rows.CityId;
        console.log({ CityCode });
        var idx = this.CityIdOptions.map((e) => e.value).indexOf(CityCode);
        console.log("idx=" + idx);
        this.CityId = idx >= 0 ? CityCode : "";
      }
    },
  },
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
      this.getTableRecords();
      this.getBloodGroupIdOptions();
    },
    resetForm() {
      this.$refs.form1.reset();
      this.$refs.form2.reset();
      this.$refs.form3.reset();
      this.$refs.form4.reset();
    },
    getTableRecords() {
      console.log("getTableRecords called");

      var server_url = companyConfig.apiURL;
      var token = this.$session.get("token");
      token = token == (null || undefined) ? 0 : token;
      var add_url = server_url + "api/members/show";
      var upload = {
        UserInterface: 1,
        MemberId: this.MemberId,
      };
      console.log("upload=" + JSON.stringify(upload));
      console.log(
        "server_url=" + server_url + ", token=" + token + ", add_url=" + add_url
      );

      const thisIns = this;
      var output = "";
      var records = "";
      var successTxt = "";
      var errorTxt = "";
      var flag = 0;

      this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");

      this.$http({
        url: add_url,
        method: "POST",
        data: upload,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        onUploadProgress: function (progressEvent) {
          this.uploadPercentage = parseInt(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          );
        }.bind(this),
      })
        .then(function (response) {
          console.log("response=" + JSON.stringify(response));

          thisIns.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");

          output = response.data.output;
          flag = response.data.flag;
          output = response.data.output;
          records = response.data.records[0];
          console.log("output=" + output + ", flag=" + flag);
          successTxt = response.data.success;
          errorTxt = response.data.error;
          console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

          if (flag == 1) {
            thisIns.rows = records;
          } else {
            thisIns.toast("error", output);
          }
        })
        .catch(function (error) {
          console.log("error=" + error);
          thisIns.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
          // this.toast("error", output);
        });
    },
    confirmAlert() {
      var validate1 = this.$refs.form1.validate();
      var validate2 = this.$refs.form2.validate();
      var validate3 = this.$refs.form3.validate();
      var validate4 = this.$refs.form4.validate();
      console.log(
        "validate1=" +
          validate1 +
          ", validate2=" +
          validate2 +
          ", validate3=" +
          validate3 +
          ", validate4=" +
          validate4
      );
      if (validate1 && validate2 && validate3 && validate4) {
        Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this! Kindly check the information are correct",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: `Continue`,
          confirmButtonColor: "#3085d6",
          cancelButtonText: `Cancel`,
          cancelButtonColor: "#d33",
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.submitForm();
          } else {
            this.sweetAlert("error", "You cancelled the process", true);
          }
        });
      } else {
        this.toast("error", "Kindly fill the required fields", true);
      }
    },
    validateStep(step) {
      console.log("validateStep step=" + step);
      var flag = false;
      var validate = false;
      var nextStep = 0;
      var message = "Kindly fill the required fields. ";
      switch (step) {
        case 1:
          validate = this.$refs.form3.validate();
          // flag = validate ? true : false;
          // message = !validate ? message : "";

          flag = validate && this.MobileNoVerified ? true : false;
          message = !this.MobileNoVerified
            ? message + "Please verify your mobile number to proceed. "
            : message;

          nextStep = 2;

          break;

        case 2:
          validate = this.$refs.form2.validate();
          flag = validate ? true : false;
          nextStep = 3;
          break;

        case 3:
          var DOB = this.DOB;
          validate = this.$refs.form1.validate();
          flag = DOB != "" && validate ? true : false;
          message = !validate ? message : "";
          message =
            DOB == ""
              ? message + " Date of birth should not be empty. "
              : message;
          nextStep = 4;
          break;

        case 4:
          validate = this.$refs.form4.validate();
          flag = validate ? true : false;
          nextStep = 5;
          break;

        default:
          break;
      }
      console.log("flag=" + flag + ", nextStep=" + nextStep);
      if (flag) {
        this.step = nextStep;
      } else {
        // this.sweetAlert("error", "Kindly fill the required * fields", false);
        this.toast("error", message, true);
      }
    },
    getBloodGroupIdOptions() {
      console.log("getBloodGroupIdOptions called");
      this.LoadingFlag = true;
      this.BloodGroupIdOptionsLoading = true;
      var selectbox1_source = "BloodGroupId";
      var selectbox1_destination = "BloodGroupIdOptions";
      var selectbox1_url = "api/sub-category/options";
      var selectbox1_conditions_array = {
        UserInterface: 1,
        CategoryMainId: 2,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getStateIdOptions() {
      console.log("getStateIdOptions called");
      this.LoadingFlag = true;
      this.StateIdOptionsLoading = true;
      var selectbox1_source = "StateId";
      var selectbox1_destination = "StateIdOptions";
      var selectbox1_url = "api/states/options";
      var selectbox1_conditions_array = {
        UserInterface: 1,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getDistrictIdOptions() {
      console.log("getDistrictIdOptions called");
      var StateId = this.StateId;
      console.log({ StateId });
      if (StateId != "") {
        this.LoadingFlag = true;
        this.DistrictIdOptionsLoading = true;
        var selectbox1_source = "DistrictId";
        var selectbox1_destination = "DistrictIdOptions";
        var selectbox1_url = "api/district/options";
        var selectbox1_conditions_array = {
          UserInterface: 1,
          StatesCode: StateId,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      }
    },
    getCityIdOptions() {
      console.log("getCityIdOptions called");
      var DistrictId = this.DistrictId;
      if (DistrictId != "") {
        this.LoadingFlag = true;
        this.CityIdOptionsLoading = true;
        var selectbox1_source = "CityId";
        var selectbox1_destination = "CityIdOptions";
        var selectbox1_url = "api/city/options";
        var selectbox1_conditions_array = {
          UserInterface: 2,
          DistrictCode: DistrictId,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      }
    },
    enableResendOtp() {
      console.log("enableResendOtp is called");
      this.SendOtpFlag = true;
      setTimeout(
        function () {
          this.ResendOtpFlag = true;
        }.bind(this),
        6000
      );
    },
    sendOTP() {
      console.log("sendOTP is called");

      var Username = this.$session.get("Username");
      var MobileNo = this.MobileNo;
      console.log("Username=" + Username + ", MobileNo=" + MobileNo);

      if (Username != "" && MobileNo != "") {
        this.SendOtpLoadingFlag = true;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/sms/send-otp";
        var upload = {
          UserInterface: "1",
          Username: Username,
          MobileNo: MobileNo,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.SendOtpLoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            if (flag) {
              thisIns.VerifyOtpFlag = true;
              thisIns.toast("success", output, true);
              thisIns.enableResendOtp();
            } else {
              thisIns.toast("error", output, true);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.SendOtpLoadingFlag = false;
          });
      } else {
        var message = "";
        if (!validate1) {
          message += "Kindly fill the required fields";
        }
        thisIns.sweetAlert("error", message, false);
      }
    },
    verifyOTP() {
      console.log("verifyOTP is called");

      var Username = this.$session.get("Username");
      var MobileNo = this.MobileNo;
      var OtpNumber = this.OtpNumber;
      console.log("Username=" + Username + ", OtpNumber=" + OtpNumber);

      if (Username != "" && MobileNo != "" && OtpNumber != "") {
        this.VerifyOtpLoadingFlag = true;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/sms/verify-otp";
        var upload = {
          UserInterface: 1,
          Username: Username,
          MobileNo: MobileNo,
          OtpNo: OtpNumber,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.VerifyOtpLoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            if (flag) {
              thisIns.toast("success", output, true);
              thisIns.MobileNoVerified = true;
              thisIns.MobileNoFlag = true;
              thisIns.OtpNumberFlag = true;
              thisIns.VerifyOtpFlag = false;
            } else {
              thisIns.toast("error", output, true);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.SendOtpLoadingFlag = false;
          });
      } else {
        var message = "";
        if (!validate1) {
          message += "Kindly fill the required fields";
        }
        thisIns.sweetAlert("error", message, false);
      }
    },
    submitForm() {
      console.log("submitForm is called");

      var validate1 = this.$refs.form1.validate();
      var validate2 = this.$refs.form2.validate();
      var validate3 = this.$refs.form3.validate();
      // var validate4 = this.$refs.form4.validate();
      console.log(
        "validate1=" +
          validate1 +
          ", validate2=" +
          validate2 +
          ", validate3=" +
          validate3
      );

      if (validate1 && validate2 && validate3) {
        this.SubmitFlag = true;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;

        var HomePageUrl = this.$session.get("HomePageUrl");
        HomePageUrl =
          HomePageUrl == (null || undefined) ? "/my-profile" : HomePageUrl;
        console.log("HomePageUrl=" + HomePageUrl);

        var add_url = server_url + "api/members/quick-update";
        var upload = {
          DateOfBirth: this.DOB,
          Gender: this.Gender,
          MartialStatus: this.MartialStatus,
          BloodGroupId: this.BloodGroupId,
          DonateBlood: this.DonateBlood,
          StateId: this.StateId,
          DistrictId: this.DistrictId,
          CityId: this.CityId,
          Line1: this.Line1,
          Line2: this.Line2,
          Area: this.Area,
          Pincode: this.Pincode,
          MobileNo: this.MobileNo,
          WhatsappNo: this.WhatsappNo,
          EmailId: this.EmailId,
          CurrentPassword: this.CurrentPassword,
          NewPassword: this.NewPassword,
          ConfirmPassword: this.ConfirmPassword,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.SubmitFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            if (flag == 1) {
              thisIns.resetForm();
              thisIns.sweetAlert("success", output, false);
              // thisIns.toast("success", output, true);
              // thisIns.LogoutUser();
              thisIns.$router.push(HomePageUrl);
            } else {
              thisIns.sweetAlert("error", output, false);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.SubmitFlag = false;
          });
      } else {
        var message = "";
        if (!validate1) {
          message += "Kindly fill the required fields";
        }
        thisIns.sweetAlert("error", message, false);
      }
    },
  },
  beforeMount() {
    console.log("quick profile update beforeMount");

    var MemberId = this.$session.get("MemberId");
    MemberId = MemberId == (null || undefined) ? 0 : MemberId;
    this.MemberId = MemberId;

    var MemeberFullname = this.$session.get("MemeberFullname");
    MemeberFullname =
      MemeberFullname == (null || undefined) ? "" : MemeberFullname;
    this.MemeberFullname = MemeberFullname;
    this.refreshPageData();
  },
};
</script>
<style lang="scss">
#quick-profile-update {
  .v-btn--floating {
    position: relative;
  }
  .v-speed-dial--bottom {
    bottom: 12%;
  }
  .v-speed-dial--right {
    right: 5%;
  }
  .v-card__subtitle,
  .v-card__text {
    font-size: 16px;
  }

  .v-list-item--dense .v-list-item__title,
  .v-list-item--dense .v-list-item__subtitle,
  .v-list--dense .v-list-item .v-list-item__title,
  .v-list--dense .v-list-item .v-list-item__subtitle {
    font-size: 18px !important;
  }
}
</style>